<template>
    <div class="lunbo">
        <el-row class="pb">
            <el-col :span="24"><el-button icon="el-icon-circle-plus-outline" @click="goCreate">新增</el-button></el-col>
        </el-row>

        <el-table ref="mainTable" :data="dataList" style="width: 100%" stripe border>
            <el-table-column prop="avatar" label="图片" width="220" header-align="center">
                <template slot-scope="scope">
                    <img :src="scope.row.image | getImageUrl(CDN)" style="width:200px" />
                </template>
            </el-table-column>
            <el-table-column prop="title" label="大标题" header-align="center"></el-table-column>
            <el-table-column prop="subhead" label="副标题" header-align="center"></el-table-column>
            <el-table-column prop="display" label="前端展示" align="center">
                <template slot-scope="scope">
                    <el-switch :active-value="1" :inactive-value="0" v-model="scope.row.display" @change="toggleDisplay(scope.row.id)" active-color="#13ce66" inactive-color="#ff4949"> </el-switch>
                </template>
            </el-table-column>
            <el-table-column prop="createTime" label="创建日期" align="center"></el-table-column>
            <el-table-column fixed="right" label="操作" width="100" align="center">
                <template slot-scope="scope">
                    <el-button @click="handleClick('deliver', scope.row)" type="text" size="small">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>
<script>
export default {
    data() {
        return {
            pageNo: 1,
            pageSize: 10,
            totalCount: 0,
            dataList: [],
            CDN: this.$cdn(),
        };
    },
    filters: {
        getImageUrl: function(src, cdn) {
            return cdn + src;
        },
    },
    mounted() {
        this.loadDataList();
    },
    methods: {
        formatter(row, column, value) {
            return value;
        },
        loadDataList: function() {
            this.$http({
                url: "/sys/operation/lunboList",
                data: {
                    pageNo: this.pageNo,
                    pageSize: this.pageSize,
                },
            }).then((res) => {
                this.totalCount = parseInt(res.data.total);
                this.dataList = res.data.records;
            });
        },
        handleClick: function(action, row) {
            this.$confirm("确定要删除此内容吗？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    this.$http({
                        url: "/sys/operation/deleteLunbo",
                        data: {
                            id: row.id,
                        },
                    }).then((res) => {
                        this.loadDataList();
                    });
                })
                .catch(() => {});
        },
        // 翻页
        toggleDisplay: function(id) {
            this.$http({
                url: "/sys/operation/toggleLunboState",
                data: {
                    id: id,
                },
            }).then((res) => {
                this.loadDataList();
            });
        },
        // 跳转
        goCreate: function() {
            this.$router.push("/operate/lunbo-create");
        },
    },
};
</script>
<style></style>
